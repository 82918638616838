/* eslint-disable */

const PI_2 = Math.PI * 2
const CONFETTI_NUMBER = 600
const CONFETTI_COLORS = [
  [100, 50, 250],
  [255, 60, 120],
  [255, 140, 0],
  [255, 205, 0],
  [0, 200, 70],
  [0, 125, 255],
  [255, 0, 40],
]

const range = function(a, b) {
  return (b - a) * Math.random() + a
}

class ConfettiItem {
  constructor(context, w, h) {
    this.context = context
    this.w = w
    this.h = h
    this.style = CONFETTI_COLORS[~~range(0, CONFETTI_COLORS.length)]
    this.rgb = `rgba(${ this.style[0] }, ${ this.style[1] }, ${ this.style[2] }`
    this.r = ~~range(3, 10)
    this.r2 = 2 * this.r
    this.replace()
  }

  replace() {
    this.opacity = 1
    this.dop = 0.03 * range(1, 4)
    this.x = this.w / 2
    this.y = range(-this.h * 1.5, -this.h / 2)
    this.ystart = this.y
    this.xmax = this.w - this.r
    this.ymax = this.h - this.r
    this.vx = range(-30, 30)
    this.vy = ~~range(25, 45)
    this.vxdir = this.vx > 0 ? 1 : -1
    this.vxmin = this.vx / 15
    this.vymin = ~~range(6, 10)

    this.vxspeed = -Math.abs(this.vx / 40)
    this.vyspeed = -Math.abs(this.vy / 40)
    this.time = 0
  }

  draw() {
    this.time++
    this.x += this.vx
    this.y += this.vy

    this.vx += this.vxspeed * this.vxdir
    this.vxspeed = this.vxspeed / 1.03

    this.vy += this.vyspeed

    if (this.time < 60) {
      this.vyspeed = this.vyspeed / 1.03
    } else {
      this.vyspeed = 0.1
    }

    if (this.x < 0 || this.x > this.xmax) {
      this.opacity = 0
    }

    return this.drawCircle(~~this.x, ~~this.y, this.r, this.rgb + ',' + this.opacity + ')')
  }

  drawCircle(x, y, r, style) {
    this.context.beginPath()
    this.context.arc(x, y, r, 0, PI_2, false)
    this.context.fillStyle = style
    this.context.fill()
  }
}

export default class Confetti {
  constructor() {
    $('body').append('<canvas class="confetti-old"></canvas>')

    this.canvas = $('.confetti-old')[0]
    this.context = this.canvas.getContext('2d')
    this.confetti = []

    $(document).on('boxOpened', this.start.bind(this))
    $(window).on('resize', this.init.bind(this))

    this.init()
  }

  init() {
    this.w = this.canvas.width = window.innerWidth
    this.h = this.canvas.height = window.innerHeight
  }

  start() {
    for (let i = 0; i < CONFETTI_NUMBER; i++) {
      this.confetti.push(
        new ConfettiItem(this.context, this.w, this.h),
      )
    }


    this.draw()
  }

  draw() {
    window.requestAnimationFrame(this.draw.bind(this))

    this.context.clearRect(0, 0, this.w, this.h)

    this.confetti.forEach(confettiItem => {
      confettiItem.draw()
    })
  }
}
